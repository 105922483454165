<template>
    <div class="content-area">

        <div class="flex flex-column flex-wrap justify-content-center mb-3">
            <div class="flex flex-row flex-wrap justify-content-between gap-1">
                <Button label="Aktivierte Sensoren" class="mb-1 mr-1 text-sm" 
                        :class="{'selected-filter' : (m_ShowActivated == 1), 'nonselected-filter' : (m_ShowActivated != 1)}"
                        @click="onClickFilterButton(1)" />

                <Button label="Nicht aktivierte Sensoren" class="mb-1 text-sm" 
                        :class="{'selected-filter' : (m_ShowActivated != 1), 'nonselected-filter' : (m_ShowActivated == 1)}"
                        @click="onClickFilterButton(0)" />
            </div>
        </div>

        <div class="flex flex-none align-content-center align-items-center justify-content-between flex-wrap gap-1">
            <div >{{perspectiveLabel}}</div>
            <div class="flex flex-none">
                <span class="p-input-icon-left">
                <i class="pi pi-filter" />
                <InputText type="text" class="p-inputtext-sm" v-model="m_sSearchText" placeholder="Filtern" @input="showItems()" />
                </span>
            </div>
        </div>
        <hr />

        <div class="scrollable-flex gap-list">
            <sensor-list-item v-for="(a) in m_Items" 
                class="sensor-list-item"
                :key="a.pkey" 
                :class="{'selected-list-item' : m_SelectedSensorPkey === a.pkey, 'non-selected-list-item' : m_SelectedSensorPkey !== a.pkey}" 
                :sensor="a" @click="onClickItem(a)">
            </sensor-list-item>
        </div>

    </div>
</template>

<script>
import { ref, computed, watch, onMounted} from  "vue";
import { useStore } from "vuex";
import SensorListItem from './SensorListItem.vue';

export default ({
    name: "Sensors",
    components: { SensorListItem },
    emits: ["sensorItemSelected"],
    setup(props, context) {

        const store = useStore();
        const m_sSearchText = ref("");
        const m_Items = ref([]);
        const m_SelectedSensorPkey = ref(0);
        const m_ShowActivated = ref(1);
        let m_componentMounted = false;
        let m_componentInitialized = false;
        
        onMounted(() => {
            m_componentMounted = true;
            store.dispatch("refreshSensors"); 
            store.dispatch("refreshGateways");
            initWhenReady();
        });

        watch(() => store.getters.isready, function () { initWhenReady(); });
        watch(() => store.getters.sensors, function () { showItems(); });

        const initWhenReady = async () => {
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;
            
            showItems();
        }

        const matchesSearch = (sensor) =>
        {
            if (!matchAssignment(sensor)) return false;

            let txt = m_sSearchText.value.toLowerCase();
            let bInclude = sensor.id.toLowerCase().includes(txt);
            if (!bInclude)
            {
                // chech plant id
                let p = sensor?.plantid ?? "";
                bInclude = (p.length > 0 && p.toLowerCase().includes(txt))
                //if (!bInclude)
                // TODO - eventually check the Address
            }

            return bInclude;
        }

        const matchAssignment = (sensor) => {
            if (1 == m_ShowActivated.value) {
                return (sensor.assignment == 1);
            }
            else {
                return (sensor.assignment != 1);
            }
        }

        const showItems = () => {
            //console.log("Show items " + JSON.stringify(store.getters.sensors));
            if (m_sSearchText.value.length > 0) {
                m_Items.value = store.getters.sensors.filter(matchesSearch);
            }
            else {
                m_Items.value = store.getters.sensors.filter(matchAssignment);
            }
        };

        const onClickItem = (it) => {
            m_SelectedSensorPkey.value = it.pkey;
            context.emit('sensorItemSelected', it);
        }

        const onClickFilterButton = (option) => {
            m_ShowActivated.value = option;
            showItems();
        }

        const perspectiveLabel = computed( () => { 
            return "Sensoren ("+store.getters.sensors.length+")";
        });

        return {m_sSearchText, m_Items, showItems, onClickItem, perspectiveLabel, m_SelectedSensorPkey, onClickFilterButton, m_ShowActivated}
    },
})
</script>

<style scoped>



</style>

