<template>
    <div class="flex flex-auto" >
        <div class="w-3 mr-4 flex flex-auto flex-column overflow-auto">
            <!--
            <div v-if="isAgricultureSelected" @click="m_SelectedAgricultureKey=0" 
                class="surface-0 mb-4 py-3 px-1 border-round cursor-pointer" >
                <div class="selectedagriculture m-auto"><span class="mr-1 material-icons">chevron_left</span> {{nameOfSelectedAgriculture}}</div>
            </div>
            
            <Kulturenliste v-if="!isAgricultureSelected" v-model:selectedagriculturekey="m_SelectedAgricultureKey" />
            -->
            <Sensors @sensorItemSelected="onSensorSelected" />
        </div>

        
        <div class="w-9 p-4 border-round flex flex-auto flex-column overflow-auto" :class="{'surface-0' : m_SelectedSensor}" >
            <div v-if="m_SelectedSensor" class="flex flex-auto flex-column" >
                <h3>Sensor {{m_SelectedSensor.id}}</h3>
                <TabView ref="tabview1">
                    <TabPanel header="INFO">
                        <h3>Information</h3>
                        <div class="text-sm text-primary">{{selecteSensorAddressText}}</div>
                        <div>Basisstation {{m_SelectedSensor.gatewayid}}, COM-Addresse {{m_SelectedSensor.comaddr}}</div>
                        <div>Status {{selectedSensorStatusString}}</div>

                        <div class="my-2"></div>
                        <div v-if="hasSelectedSensorBeenDeployed" >Eingecheckt: {{selectedSensorDeployFootprint.timestamp}}</div>
                        <div v-if="hasSelectedSensorBeenCollected" >Eingesammelt: {{selectedSensorCollectedFootprint.timestamp}}</div>
                        
                        <div class="my-2"></div>
                        <div v-if="hasSelectedSensorBeenDeployed">Eingecheckt von {{selectedSensorDeployFootprint.person}}@{{selectedSensorDeployFootprint.device}}</div>
                        <div v-if="hasSelectedSensorBeenCollected">Eingesammelt von {{hasSelectedSensorBeenCollected.person}}@{{hasSelectedSensorBeenCollected.device}}</div>
                        
                        <div class="my-2"></div>
                        <SensorLocationWidget :sensor="m_SelectedSensor" style="height:10em; width:30em" />
                        <div>Standort</div>

                        <div class="mt-4 relative" style="width:30em">
                            <ProgressBar style="height:1em;" :value="selectedSensorBatteryLevel" :showValue="false" />
                            <div>Akkustand</div>
                            <div class="absolute top-0 right-0">
                                <p class="mt-3">{{selectedSensorBatteryLevel}}%</p>
                            </div>
                        </div>
                        
                    </TabPanel>
                    <TabPanel header="MELDUNGEN">
                        <IssuesList :sensorkey="selectedSensorPkey" :enable-header="false" @issueItemSelected="onClickIssueItem" />
                    </TabPanel>
                    <TabPanel v-if="hasAssociatedCharts" header="ANALYSE">
                        <div class="flex flex-auto flex-column flex-wrap gap-1">
                            <template v-for="(cha) in m_AssociatedCharts" :key="cha.pkey" >
                                <div class="chart-item">
                                    <h4>{{cha.caption}}</h4>
                                    <Chart :type="cha.type" :data="cha.data" :options="cha.options" />
                                </div>
                            </template>
                        </div>
                    </TabPanel>
                    <TabPanel header="KLIMADATEN">
                        <h3>Gemessene Klimadaten</h3>
                        <div class="flex-content gap-1">
                            <div class="dummy-chart-item"><img class="object-fit-cover" src="../assets/dummy-klima-temp.png" /></div>
                            <div class="dummy-chart-item"><img class="object-fit-cover" src="../assets/dummy-klima-humidity.png" /></div>
                            <div class="dummy-chart-item"><img class="object-fit-cover" src="../assets/dummy-klima-light.png" /></div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from  "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Sensors from './Sensors.vue';
import IssuesList from './IssuesList.vue';
import SensorLocationWidget from './SensorLocationWidget.vue'

export default ({
    name: "InstallationPage",
    components: { Sensors, IssuesList, TabView, TabPanel, SensorLocationWidget },
    props: {
        displaysensorkey: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const m_SelectedAgricultureKey = ref(0);
        const m_SelectedSensor = ref(null);
        const selectedSensorBatteryLevel = ref(89);
        const m_AssociatedCharts = ref([]);

        let m_componentMounted = false;
        let m_componentInitialized = false;
        
        onMounted(() => {
            m_componentMounted = true;
            initWhenReady();
        });

        watch(() => store.getters.isready, function () { initWhenReady(); });
        watch(() => props.displaysensorkey, () => { selectSensorByPkey(props.displaysensorkey); });

        const initWhenReady = async () => {
            store.dispatch("requireSensors");
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;

            selectSensorByPkey(props.displaysensorkey);
        }

        const selectSensorByPkey = (sensorpkey) => {
            if (sensorpkey > 0) {
                let sensor = store.getters.findSensorByPkey(sensorpkey);
                if (sensor) onSensorSelected(sensor);
            }
        }

        const onSensorSelected = (sensor) => { 
            m_SelectedSensor.value = sensor;
            m_AssociatedCharts.value = store.getters.getChartsBySensorID(sensor.id);
            //console.log("Selected sensor changed to " + JSON.stringify(sensor));
        };

        const onClickIssueItem = (feed) => {
            //console.log("Navigate to feed " + feed.pkey);
            router.push({name:"feed", params: {displayfeedkey: feed.pkey} });
        }

        const selectedSensorPkey = computed( () => { 
            return m_SelectedSensor.value?.pkey ?? 0;
        });

        const nameOfSelectedAgriculture = computed( () => { 
            return store.getters.getAgricultureNameByPkey(m_SelectedAgricultureKey.value);
        });

        const isAgricultureSelected = computed( () => { 
            return (m_SelectedAgricultureKey.value > 0);
        });

        const hasAssociatedCharts = computed( () => { 
            return (m_AssociatedCharts.value.length > 0);
        });

        const selecteSensorAddressText = computed( () => { 
            if (m_SelectedSensor.value) {
                return store.getters.getSensorAddressText(m_SelectedSensor.value);
            }
            return "";
        });

        const hasSelectedSensorBeenDeployed = computed( () => { 
            if (m_SelectedSensor.value) {
                if (m_SelectedSensor.value.pkey > 0) {
                    return (m_SelectedSensor.value.deployed);
                }
            }
            return false;
        });

        const hasSelectedSensorBeenCollected = computed( () => { 
            if (m_SelectedSensor.value) {
                if (m_SelectedSensor.value.pkey > 0) {
                    return (m_SelectedSensor.value.assignment == 2 && m_SelectedSensor.value.collected);
                }
            }
            return false;
        });

        const selectedSensorDeployFootprint = computed( () => { 
            if (m_SelectedSensor.value) {
                if (m_SelectedSensor.value.pkey > 0) {
                    if (m_SelectedSensor.value.deployed) {
                        return m_SelectedSensor.value.deployed;
                    }
                }
            }
            return {person:"", device:"", timestamp:""};
        });

        const selectedSensorCollectedFootprint = computed( () => { 
            if (m_SelectedSensor.value) {
                if (m_SelectedSensor.value.pkey > 0) {
                    if (m_SelectedSensor.value.collected) {
                        return m_SelectedSensor.value.collected;
                    }
                }
            }
            return {person:"", device:"", timestamp:""};
        });

        const selectedSensorStatusString = computed( () => { 
            if (m_SelectedSensor.value) {
                if (m_SelectedSensor.value.pkey > 0) {
                    switch (m_SelectedSensor.value.status) {
                        case 0: return "Unbekannt";
                        case 1: return "Aktiv";
                        case 2: return "Inaktiv";
                        case 3: return "Fehler";
                    }
                }
            }
            return "---";
        });

        return { selectedSensorPkey, onClickIssueItem,
                isAgricultureSelected, 
                nameOfSelectedAgriculture, 
                m_SelectedAgricultureKey, 
                onSensorSelected, 
                m_SelectedSensor, m_AssociatedCharts, hasAssociatedCharts,
                selectedSensorBatteryLevel,
                 selecteSensorAddressText,
                 hasSelectedSensorBeenDeployed, selectedSensorDeployFootprint, selectedSensorStatusString,
                 hasSelectedSensorBeenCollected, selectedSensorCollectedFootprint };
    },
})
</script>

<style scoped>

.content-left {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-content: flex-start;
}

.kultur {
    width: 300px;
    padding: 1em;
    margin-bottom: 2em;
    margin-right: 2em;
    margin-left: 0;
    background: #fff;
}

.selectedagriculture
{
    font-weight: 600;
    font-size: 1.6rem;
}

::v-deep(.p-tabview)
{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

::v-deep(.p-tabview-panels)
{
    background-color: var(--surface-a);
    color: var(--text-color-primary);
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

::v-deep(.p-tabview-nav)
{
    background-color: var(--surface-a);
    color: var(--text-color-primary);
    justify-content: space-around;
}

::v-deep(.p-tabview-nav-link)
{
    background-color: var(--surface-a);
    color: var(--text-color-primary);
}

::v-deep(.p-tabview-panel)
{
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-content: flex-start;
}


.object-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover; /*magic*/
}

</style>

