<template>
    <ol-map :loadTilesWhileAnimating="true" :loadTilesWhileInteracting="true" style="width: 100%" >

        <ol-view id="view" ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection" />

        <MyTileLayer>
            <ol-source-osm />
        </MyTileLayer>

        <ol-vector-layer >
            <ol-source-vector :projection="projection" :features="sensorFeaturesOK"></ol-source-vector>
            <ol-style>
                <ol-style-icon :src="iconSensorOK" :scale="1"></ol-style-icon>
            </ol-style>
        </ol-vector-layer>

        <ol-vector-layer >
            <ol-source-vector :projection="projection" :features="sensorFeaturesNOK"></ol-source-vector>
            <ol-style>
                <ol-style-icon :src="iconSensorNOK" :scale="1"></ol-style-icon>
            </ol-style>
        </ol-vector-layer>

    </ol-map>
</template>

<script>
import { ref, watch, onMounted} from  "vue";
import { useStore } from "vuex";
//import Sensor from './dbentities.ts';
import Feature from 'ol/Feature';
//import Polygon from 'ol/geom/Polygon';
import {fromLonLat} from 'ol/proj';
import Point from 'ol/geom/Point';
import MyTileLayer from './MyTileLayer.vue';
import iconSensorOK from '@/assets/sensorok.png';
import iconSensorNOK from '@/assets/sensornok.png';

export default ({
    name: "SensorLocationWidget",
    components: { MyTileLayer },
    props: {
        sensor: {
            type: Object,
            default: () => { return {pkey:0,id:"",activefeedscount:0,gatewayid:"",assignment:3,status:0,plantid:"",geolocation:"",comment:"",address:[""]} }
        }
    },
    setup(props) {
        const store = useStore();
        const center = ref([1049331.134767886,6215020.94753701]);
        const rotation = ref(0);
        const zoom = ref(20);
        const projection = ref('EPSG:3857'); // use Spherical Mercator
        const view = ref(null);
        const sensorFeaturesOK = ref([]); // Array of the features for the sensor without Issues
        const sensorFeaturesNOK = ref([]); // Array of the features for the sensor without Issues

        onMounted(() => {
            onSensorChanged();
        });

        watch(() => props.sensor, function () { onSensorChanged(); });

        const centerToSensor = () => {
            // initialize the center with the coordinates to the installation
            let coord = [store.getters.geolocation.lon, store.getters.geolocation.lat];
            let sen = props.sensor;
            if (sen.pkey > 0 && sen.geolocation?.latitude && sen.geolocation?.longitude)
            {
                coord = [sen.geolocation.longitude, sen.geolocation.latitude];
                zoom.value = 18;
            }
            else {
                zoom.value = 14;
            }
            center.value = fromLonLat(coord, projection.value);
        }

        const onSensorChanged = async () => {
            let featArrOK = [];
            let featArrNOK = [];
            let sen = props.sensor;

            if (sen.pkey > 0 && sen.geolocation?.latitude && sen.geolocation?.longitude)
            {
                let f = new Feature({ geometry: new Point(fromLonLat([sen.geolocation.longitude, sen.geolocation.latitude]), projection.value), sensor: sen, name: sen.id });
                if (sen.activefeedscount > 0) {
                    featArrNOK.push(f);
                }
                else {
                    featArrOK.push(f);
                }
            }
            sensorFeaturesOK.value = featArrOK;
            sensorFeaturesNOK.value = featArrNOK;
            centerToSensor();
        }

        return { center, rotation, zoom, projection, view, sensorFeaturesOK, sensorFeaturesNOK, iconSensorOK, iconSensorNOK };
    }
})
</script>

<style scoped>

</style>

